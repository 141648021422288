import React from "react";
import PropTypes from "prop-types";

import Snackbar from "@material-ui/core/Snackbar";
import Save from "@material-ui/icons/Save";

class Notification extends React.Component {
  onClose = () => this.props.dispatch("notification", notification);

  renderMessage = () => {
    const { message, type } = this.props;
    if (!type) {
      return message;
    }
    switch (type) {
      case "SAVE":
        return (
          <div className="u-flexCenter">
            <Save className="u-marginRight10" />
            Данные успешно сохранены
          </div>
        );
    }
    return "";
  };

  render() {
    const { show } = this.props;
    return (
      <Snackbar
        className="notification-bar"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={show}
        autoHideDuration={3000}
        onClose={this.onClose}
        ContentProps={{
          "aria-describedby": "notification-message",
        }}
        message={<span id="notification-message">{this.renderMessage()}</span>}
      />
    );
  }
}

Notification.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export const notification = {
  message: "",
  type: "",
  show: false,
};

export default Notification;
