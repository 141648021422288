import React, {useState} from "react";
import {Form, withFormik} from "formik";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {dataSourceV2} from "commons-ui/core/DataSource";
import { DatePicker } from "@material-ui/pickers";
import Alert from '@material-ui/lab/Alert';

export const emailRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function SubscribeForm(props) {
    const {values, errors, touched, setFieldValue, isSubmitting} = props;
    const [captcha, setCaptcha] = useState(false);
    const [alertOn, setAlertOn] = useState(false);


    const thankyou = () => {
        setAlertOn(true)
    }
    return (<Form noValidate="" className="u-sizeFullWidth" role="">
        <div className="">
            <h2 className="u-flex u-justifyContentCenter u-fontSize18 u-flexCenter u-fontWeightBold u-marginBottom5">
                Subscribe to our Updates
            </h2>
        </div>
        <div style={{color:"#000000"}}>

            <TextField error={errors.email && touched.email}
                       label={<span className="u-textColorDark">Email *</span>}
                       helperText={errors.email && touched.email ? errors.email : ""}
                       margin="dense"
                       variant="outlined"
                       inputProps={{ "aria-label": "Email", "aria-required": "true" }}
                       className="u-sizeFullWidth u-marginVertical8"
                       onChange={(e) => setFieldValue("email", e.target.value)}
                       value={values.email}/>


            <DatePicker
              disableFuture
              openTo="year"
              variant="inline"
              ampm={false}
              className="u-sizeFullWidth u-marginVertical12"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              views={["year", "month", "date"]}
              margin="dense"
              inputProps={{ "aria-label": "Birthday", "aria-required": "true" }}
              id="date-picker-birthday-footer"
              label={<span className="u-textColorDark">Birthday</span>}
              error={errors.date_of_birth && touched.date_of_birth}
              helperText={(errors.date_of_birth && touched.date_of_birth) ? errors.date_of_birth : undefined}
              value={values.date_of_birth ? new Date(values.date_of_birth) : null}
              onChange={(date) => {
                    let value = null
                    if (!date || isNaN(date.getTime())) {
                        value = null
                    } else {
                        date.setHours(12);
                        value = date.toISOString()
                    }
                    setFieldValue("date_of_birth", value)
                    
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />

            <div className="u-flexCenter">
                <TextField error={errors.city && touched.city}
                           label={<span className="u-textColorDark">City</span>}
                           helperText={errors.city && touched.city ? errors.city : ""}
                           margin="dense"
                           variant="outlined"
                           inputProps={{ "aria-label": "City", "aria-required": "true" }}
                           className="u-sizeFullWidth u-marginVertical8 u-marginRight5"
                           onChange={(e) => setFieldValue("city", e.target.value)}
                           value={values.city}/>
                       <TextField error={errors.postal_code && touched.postal_code}
                           label={<span className="u-textColorDark">ZipCode</span>}
                           helperText={errors.postal_code && touched.postal_code ? errors.postal_code : ""}
                           margin="dense"
                           inputProps={{ "aria-label": "ZipCode", "aria-required": "true" }}
                           variant="outlined"
                           className="u-sizeFullWidth u-marginVertical8 u-marginLeft5"
                           onChange={(e) => setFieldValue("postal_code", e.target.value)}
                           value={values.postal_code}/>
            </div>
        </div>
        <div className="u-flexCenter u-justifyContentCenter u-marginTop5">
            <Button onClick={() => thankyou()} variant="outlined" type="submit" size="large" className="button-primary" disabled={isSubmitting}>
                Subscribe
            </Button>
        </div>
        {alertOn ? 
        <Alert hidden={true} severity="success">Thank you for subscribing.</Alert>
        :
        <></>
        }  

    </Form>);
}

const defaultValues = (password) => ({email: "", city: "", postal_code: "", date_of_birth: ""});

export default SubscribeForm = withFormik({
    mapPropsToValues: (props) => defaultValues(),

    validate: (values, props) => {
        let errors = {};
        if (values.email && !emailRe.test(values.email)) {
            errors.email = "Incorrect email";
        }
        if (!values.email && !values.phone) {
            errors.email = "Cannot be empty";
        }
        return errors;
    },

    handleSubmit: (values, props) => {
        const {onSuccess, ecommerceStoreId, apiPath} = props.props;

        const item = {
            ecommerce_store_id: ecommerceStoreId,
            email: values.email,
            city: values.city,
            postal_code: values.postal_code,
            date_of_birth: values.date_of_birth
        };

        dataSourceV2("customer_subscription_create?with_mailchimp=true", {data: {item}, url: apiPath})
            .then(resp => console.log(resp));
    },
    validateOnChange: false,
    validateOnBlur: false
})(SubscribeForm);
