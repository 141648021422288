import React from "react";
import queryString from "query-string";
import { useRouter } from "../../hooks/useRouter";

import { TermsAndConditionsContent } from "../TermsAndConditionsContent";

import { useStore } from "../../context/state";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { deleteParamFromQueryString } from "commons-ui/core/utils";

export const LicenseAgreementDialog = (props) => {
  const { isMobile, dispatch } = useStore();

  const location = useRouter();
  const params = queryString.parse(location.search);

  const isOpen = !!params.license_agreement;

  const onClose = () => {
    dispatch(deleteParamFromQueryString("license_agreement", location));
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={isOpen}
      maxWidth="sm"
      onClose={onClose}
      fullWidth
      className="tree-widget"
      scroll="paper"
    >
      <div className="u-flexCenter u-justifyContentSpaceBetween">
        <DialogTitle>License Agreement</DialogTitle>
        <div>
          <Tooltip title="Close">
            <IconButton className="u-marginRight8" onClick={onClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <DialogContent dividers>
        <div className="u-padding24">
          <TermsAndConditionsContent />
        </div>
      </DialogContent>
    </Dialog>
  );
};
