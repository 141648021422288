import React, { useState, useEffect } from "react";
import cookie from "react-cookie";
import Link from "next/link";

import { useStore } from "../../../context/state";

import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Person from "@material-ui/icons/Person";

import { dataSourceV2 } from "commons-ui/core/DataSource";

import "./AccountWidget.module.scss";

export const AccountWidget = (props) => {
  const { open, isLogin, isPreLogin, onClose, apiPath } = props;
  const [customer, setCustomer] = useState({});
  const customerData = () => {
    dataSourceV2("customer_get", { url: apiPath }).then((v) => {
      if (!v.message && v.item) {
        setCustomer(v.item);
      }
    });
  };

  useEffect(() => {
    customerData();
  }, []);


  const { dispatch } = useStore();
  if (isPreLogin) {
    return (
      <>
        <Menu
          anchorEl={open}
          keepMounted
          className="account-widget-menu"
          open={Boolean(open)}
          onClose={onClose}
        >
          <Link aria-label="Continue signing up" href="/signup">
            <MenuItem
              onClick={() => {
                onClose();
              }}
            >
              Continue
            </MenuItem>
          </Link>

          <Link href="/">
            <MenuItem
              onClick={() => {
                dispatch("login", { token: undefined });
                dispatch("customer", { delete: {} });
                logout();
                onClose();
              }}
            >
              Sign Out
            </MenuItem>
          </Link>
        </Menu>
      </>
    );
  }

  if (!isLogin) {
    return (
      <>
        <Menu
          anchorEl={open}
          keepMounted
          className="account-widget-menu"
          open={Boolean(open)}
          onClose={onClose}
        >
          <Link href="/login">
            <MenuItem
              onClick={() => {
                onClose();
              }}
            >
              Sign In
            </MenuItem>
          </Link>

          <Link href="/signup">
            <MenuItem
              onClick={() => {
                onClose();
              }}
            >
              Sign Up
            </MenuItem>
          </Link>
        </Menu>
      </>
    );
  }

  return (
    <Menu
      anchorEl={open}
      keepMounted
      className="account-widget-menu"
      open={Boolean(open)}
      onClose={onClose}
    >
      <div className="u-paddingHorizontal20 u-paddingTop10 u-flexColumn u-justifyContentCenter u-alignItemsCenter u-marginBottom10">
        <Avatar className="u-width60 u-height60"><Person fontSize="large"/></Avatar>
        {
            customer.email ?
                <span className="u-marginTop5 u-textColorNormal">
                  {customer.email}
                </span>
                :
                null
        }
      </div>
      <Link href="/account">
        <MenuItem
          onClick={() => {
            onClose();
          }}
        >
          Account
        </MenuItem>
      </Link>

      <Link href="/">
        <MenuItem
          onClick={() => {
            dispatch("login", { token: undefined });
            dispatch("customer", { delete: {} });
            logout();
            onClose();
          }}
        >
          Sign Out
        </MenuItem>
      </Link>
    </Menu>
  );
};

export const logout = (dispatch) => {
  cookie.remove("token", { path: "/" });
  cookie.remove("token_refresh", { path: "/" });
  if (dispatch) {
    dispatch();
  }
};

export default AccountWidget;
