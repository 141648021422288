import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Launch from "@material-ui/icons/Launch";
import Tooltip from "@material-ui/core/Tooltip";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import queryString from "query-string";
import Close from "@material-ui/icons/Close";
import SubscribeForm from "../SubscribeForm";
import { useRouter } from "../../hooks/useRouter";
import "./style.styl";




export default function SubscribeWidget(props) {
    const {isFullscreen, isMobile, dispatch} = props;
    const location = useRouter();
    const params = queryString.parse(location.search);

    const isOpen = params["subscribe_form"];
    const onClose = () => {
        delete params["subscribe_form"];
        dispatch(location.pathname + "?"+ queryString.stringify(params));
    };


    return (
        <Dialog
            fullScreen={isFullscreen}
            maxWidth="md"
            aria-labelledby="confirmation-dialog-title"
            open={isOpen}
            onClose={onClose}

            >
                <div>
                    <div className="u-flexCenter u-justifyContentSpaceBetween u-paddingVertical5">
                        <div className="u-flexCenter u-paddingLeft24">

                        </div>

                        <div className="u-flexCenter">
                            <IconButton
                                        onClick={onClose}
                                        className="u-marginRight12">
                                <Close/>
                            </IconButton>
                        </div>
                    </div>
                    <DialogContent className="u-paddingBottom30">
                        <SubscribeForm {...props}/>
                    </DialogContent>

                </div>
        </Dialog>
    );
}
