import React from "react";
import queryString from "query-string";
import { useRouter } from "../../hooks/useRouter";


import { useStore } from "../../context/state";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Delivery from "./Delivery";

import { deleteParamFromQueryString } from "commons-ui/core/utils";

export const DeliverySelectWidget = (props) => {
  const { isMobile, apiPath, ecommerceStoreId, dispatch } = useStore();

  const location = useRouter();
  const params = queryString.parse(location.search);

  const isOpen = !!params.select_delivery;

  const onClose = () => {
    dispatch(deleteParamFromQueryString("select_delivery", location));
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={isOpen}
      maxWidth="md"
      fullWidth
      className="tree-widget"
      scroll="paper"
    >
        <DialogContent dividers>
            <div className="u-paddingHorizontal24 u-marginTop20">
                <Delivery itemIdParent="ecommerce_store_id"
                          itemIdValueParent={ecommerceStoreId}
                          modelName="ecommerce_store_delivery_option"
                          itemId="ecommerce_store_delivery_option_id"
                          onSuccess={() => onClose()}
                          apiPath={apiPath}
                          dispatch={dispatch}
                          isMobile={isMobile}/>
            </div>
        </DialogContent>
    </Dialog>
  );
};
