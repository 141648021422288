
export const getDeliveryOptionId = (item) => {
    if (!item) return "";
    return item.ecommerce_store_delivery_option_event_id || item.ecommerce_store_delivery_option_pickup_id || item.ecommerce_store_delivery_option_rule_id || "";
};



export const getMenuUrl = (delivery) => {
    console.log(2222, delivery)
    const deliveryType = delivery.delivery_type
    const id = delivery.slug || getDeliveryOptionId(delivery) || ""
    if (deliveryType === "pick_up") {
        return "/stores/" + id 
    }
    if (deliveryType === "delivery") {
        return "/location/" + id + "/?postal_code=" + (delivery.postal_code || "") + "&date=" + (delivery.delivery_requested_date || "")
    }
    if (deliveryType === "event") {
        return "/events/" + id 
    }
    return ""
}