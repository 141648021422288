import React, {useState, useEffect, useMemo} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import {dataSourceV2} from "commons-ui/core/DataSource";
import Loading from "commons-ui/core/Loading";
import PickUpForm from "./PickUpForm";
import DeliveryForm from "./DeliveryForm";
import RedirectForm from "./RedirectForm";
import AirForm from "./AirForm";
import { EventForm } from "./EventForm";
import {Form, withFormik} from "formik";
import "./style.styl";


function Delivery(props) {
    const {modelName, itemId, itemIdParent, itemIdValueParent, onSuccess, apiPath} = props;
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState("");
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState({});

    const item = useMemo(() => {
        const itemList = items.filter(v => v[itemId] === selected);
        if (itemList.length) return itemList[0];
        return {};
    }, [selected, items.length]);

    const request = () => {
        dataSourceV2(`${modelName}_list?${itemIdParent}=${itemIdValueParent}`, {url: apiPath})
            .then(v=> {
                if (!v.message && v.item_list && v.item_list.length) {
                    setSelected(v.item_list[0][itemId]);
                    setItems(v.item_list);
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        if (itemIdValueParent){
            request();
        }

    }, [itemIdValueParent]);




    return (
            <div className="delivery-step u-flexColumn">

                <div className="u-marginBottom15 u-fontWeightBold u-fontSize18">
                    Select Delivery Method
                </div>
                <div className=" u-flexCenter u-paddingBottom25 u-borderBottom1 u-borderColorLightest u-xs-flexColumn">
                    {
                        items.map((v, i) => {
                            const selectedClass = selected === v[itemId] ? " selected " : "";
                            return (
                                <div className={"delivery-option u-cursorPointer u-marginRight10 u-xs-marginRight0 u-xs-marginRight0 " + selectedClass} onClick={() => setSelected(v[itemId])}>
                                    {v.name || v.type}
                                </div>
                            );
                        })
                    }
                </div>
                <div className="u-paddingVertical15">
                    {
                        loading && <Loading className="u-marginBottom100"/>
                    }
                    {
                        item.type === "pick_up" ?
                            <PickUpForm {...props}
                                        item={item}
                                        description={item.description}
                                        itemIdValueParent={item[itemId]}
                                        itemIdParent={itemId}
                                        modelName={modelName + "_pickup"}
                                        itemId={modelName + "_pickup_id"}/>
                            :
                            (
                                item.type === "delivery" || item.type === "shipping" ?
                                    <DeliveryForm {...props} itemIdValue={item[itemId]} item={item}/>
                                    :
                                    item.type === "redirect" ?
                                        <RedirectForm {...props} itemIdValue={item[itemId]} item={item}/>
                                        :
                                        item.type === "shipping_air" || item.type === "air" ?
                                            <AirForm {...props}
                                                        item={item}
                                                        description={item.description}
                                                        itemIdValueParent={item[itemId]}
                                                        itemIdParent={itemId}
                                                        modelName={modelName + "_rule"}
                                                        itemId={modelName + "_rule_id"}/>
                                            :
                                            item.type === "event" ?
                                                <EventForm {...props}
                                                            item={item}
                                                            description={item.description}
                                                            itemIdValueParent={item[itemId]}
                                                            itemIdParent={itemId}
                                                            modelName={modelName + "_event"}
                                                            itemId={modelName + "_event_id"}/>
                                                        :
                                                        null

                            )
                    }
                </div>
                {
                    item.type !== "redirect" ?
                        <div className="u-marginBottom100 u-marginTop20">
                            <Button className="button-secondary" size="large" onClick={onSuccess}>
                                Submit
                            </Button>
                        </div>
                        :
                        null
                }
            </div>
    );
}

const initDate = () => {
    let now = new Date();
    now.setHours(12);
    return now;
};


const defaultValues = (item) => {
    const data = {};
    data.event_date_selected = initDate();
    return data;
};


export default Delivery = withFormik({
    mapPropsToValues: (props) => (defaultValues(props.item)),

    validate: (values, props) => {
        let errors = {};
        if (!values.name) {
            errors.name = "Cannot be empty";
        }
        return errors;
    },

    handleSubmit: (values, props) => {
        const {items, itemId, itemIdValue, modelName, ecommerceStoreGroupId, onCRUD, setFieldValue, apiPath} = props.props;
        const updated = values.updated;
        const update = {};


    },
    enableReinitialize: true

})(Delivery);
