import React from "react";
import Head from "next/head";

import { AppWrapper } from "../app";
import { StoreWrapper as Provider } from "../context/state";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { apiUrl } from "../constants";

import "../globalStyles/medium.css";

import "../globalStyles/style-general.scss";
import "../globalStyles/style.scss";
import "../globalStyles/theme.scss";

const App = ({ Component, pageProps }) => {
  React.useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        <link rel="preconnect" href={apiUrl} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <link
          rel="preload"
          href="/fonts/piroshky-regular.otf"
          as="font"
          crossOrigin=""
        />
      </Head>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Provider>
            <AppWrapper>
              <Component {...pageProps} />
            </AppWrapper>
          </Provider>
        </MuiPickersUtilsProvider>
    </>
  );
};

export default App;
