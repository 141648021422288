import React, {useState} from "react";
import { useStore } from "../../context/state";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Close from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./style.styl";


export default function NotificationHeader(props) {
    const {text, type, hide} = props;
    const [open, setOpen] = useState(false);
    const {isMobile} = useStore();

    if (!text || text.length < 5 || hide) {
        return null;
    }



    return (
        <div className={"u-flexCenter u-justifyContentSpaceBetween u-paddingVertical5 u-height40"}
             style={{backgroundColor: type === "info" ? "#d5d5ff" : "rgb(253, 205, 205)"}}>
            <div className="container u-flexCenter u-justifyContentCenter">
                <div className={"u-paddingHorizontal24 u-flexCenter u-justifyContentCenter u-sizeFullWidth u-xs-padding0"} style={{maxWidth: 900}}>
                    <div className=" u-reative" style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                        {text}
                    </div>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        <HelpOutline fontSize="small"/>
                    </IconButton>
                </div>
            </div>


            <Dialog
                maxWidth="sm"
                fullWidth
                aria-labelledby="confirmation-dialog-title"
                open={open}
                onClose={() => setOpen(false)}

                >
                <div>
                    <div className="u-flexCenter u-justifyContentSpaceBetween">
                        <div className="u-flexCenter">
                            <DialogTitle>Notification</DialogTitle>
                        </div>
                        <div>
                            <Tooltip title="Close">
                                <IconButton className="u-marginRight10"
                                            onClick={() => setOpen(false)}>
                                    <Close/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <DialogContent dividers className="u-paddingVertical20">
                    {text}
                </DialogContent>

            </Dialog>
        </div>
    );
}
